import { render, staticRenderFns } from "./SizeView.vue?vue&type=template&id=3c272ad0&scoped=true&"
import script from "./SizeView.vue?vue&type=script&lang=js&"
export * from "./SizeView.vue?vue&type=script&lang=js&"
import style0 from "./SizeView.vue?vue&type=style&index=0&id=3c272ad0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c272ad0",
  null
  
)

export default component.exports